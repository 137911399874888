@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #0d1117;
  color: rgb(194, 194, 194);
}

.coinsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
  background-color: #0d1117;
}

.header {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}

.inputField {
  padding: 20px;
  font-size: 14px;
  width: 260px;
  height: 42px;
  border-radius: 4px;
  border: none;
  background: #4442423b;
  color: rgb(160, 155, 155);
}

@media (max-width: 520px) {
  .header {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1.brand {
    padding-bottom: 16px;
  }
}